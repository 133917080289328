import { useEffect, useState } from "react";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

export function usePlay(roomId, setContent, content) {
  const [currentSlide, setCurrentSlide] = useState();
  const [presentationData, setPresentationData] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    if (currentSlide < 0) {
      setCurrentSlide(0);
    }
  }, [currentSlide]);

  const next = async () => {
    if (content.type !== "end") {
      const roomDocRef = doc(db, "rooms", roomId);
      await updateDoc(roomDocRef, { currentSlide: currentSlide + 1 });
    }
  };

  const back = async () => {
    if (currentSlide !== 0) {
      const roomDocRef = doc(db, "rooms", roomId);
      await updateDoc(roomDocRef, { currentSlide: currentSlide - 1 });
    }
  };

  useEffect(() => {
    if (presentationData) {
      setContent(presentationData[currentSlide]);
    }
  }, [currentSlide, setContent, presentationData]);

  useEffect(() => {
    const roomDocRef = doc(db, "rooms", roomId);

    getDoc(roomDocRef).then((res) => {
      const presRef = doc(db, "presentations", res.data().presentationId);
      getDoc(presRef).then((presDocData) => {
        let presData = [{ type: "opener" }];

        presDocData = presDocData.data();

        presDocData.files.forEach((file, index) => {
          presData.push({ type: "slide", content: file });
          presDocData.questions.forEach((question) => {
            if (question.after === index) {
              presData.push({ type: "question", content: question });
            }
          });
        });

        presData.push({ type: "end" });

        setPresentationData(presData);

        // setPresentationData(presDocData.data());
      });
    });

    const unsubscribe = onSnapshot(roomDocRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setCurrentSlide(data.currentSlide);
      }
    });

    return () => unsubscribe();
  }, [roomId, db]);

  return [next, back];
}
