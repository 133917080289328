const API_KEY = "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";
const MODEL_ENDPOINT = "https://api.openai.com/v1/completions";
const maxLength = 4000;

export async function getQuestionsFromText(text) {
  let slideContent = text.replace(/\n{2,}/g, "\n"); //remove extra new lines
  let prompt = `
QUESTION GENERATOR ----

QUESTION PARAMETERS:
- MULTIPLE CHOICE
- 4 POSSIBLE ANSWERS WITH ONE CORRECT ANSWER
- 2 SENTANCES MAX PER QUESTION AND ANSWER

FORMAT:
QUESTION:
CORRECT ANSWER:
ANSWER 2:
ANSWER 3:
ANSWER 4:

CONTENT:
${slideContent.substring(0, 6000)}
    
10 QUESTIONS:

Q1:`;

  let result = "";

  let res1 = await makeRequest(prompt);
  let res2;
  let res3;

  result = res1;

  if (result.indexOf("Q10:") === -1) {
    // console.log("Sending for second completion", result);
    res2 = await makeRequest(result);
    result = res2;
  }

  if (res2 && result.indexOf("Q10:") === -1) {
    // console.log("Sending for third completion", result);
    res3 = await makeRequest(result);
    result = res3;
  }

  // console.log("Final Prompt Completion", result);

  return extractQuestions(result);
}

async function makeRequest(prompt) {
  // console.log("Sending request to GPT");
  let response = await fetch("https://api.openai.com/v1/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    // body: '{\n    "model": "text-davinci-003",\n    "prompt": "Say this is a test",\n    "max_tokens": 7,\n    "temperature": 0\n  }',
    body: JSON.stringify({
      model: "text-davinci-003",
      prompt: prompt,
      max_tokens: 2000,
      temperature: 0,
    }),
  });
  let resJson = await response.json();

  // console.log(resJson.choices[0].text);

  return prompt + resJson.choices[0].text;
}

function extractQuestions(sampleText) {
  const questions = [];

  // find the index where the first question starts
  const startIndex = sampleText.indexOf("Q1: ");

  // extract the remaining text after the first question
  const remainingText = sampleText.substring(startIndex);

  // console.log("Remaining text:", remainingText);

  // find the indices of all the questions
  const questionIndices = Array.from(remainingText.matchAll(/Q\d+: /g)).map(
    (match) => match.index
  );

  // console.log("Question indices:", questionIndices);

  // loop through each question index
  for (let i = 0; i < questionIndices.length; i++) {
    // get the question text
    const questionIndex = questionIndices[i];
    const nextQuestionIndex =
      i < questionIndices.length - 1
        ? questionIndices[i + 1]
        : remainingText.length;
    const questionText = remainingText
      .substring(questionIndex, nextQuestionIndex)
      .replace(/^Q\d+: /, "")
      .trim();

    // console.log(`Question ${i + 1}:`, questionText);

    const arr = questionText.split("\n"); // Split the string into an array by newline characters

    const question = arr.shift(); // Remove the first element from the array and store it as the question

    const options = arr.map((option) => {
      // Map over the remaining elements in the array to create an array of options
      const letter = option.charAt(0); // Get the letter of the option (e.g. "A")
      const content = option.substring(3); // Get the content of the option (e.g. "To catch up with the agricultural and industrial gains of Western nations.")
      return { correct: false, content }; // Return an object with the letter and content as properties
    });

    const obj = { question, options }; // Create the final object with the question and options properties

    questions.push(obj);
  }

  // console.log("Questions:", questions);

  // console.log(JSON.stringify(questions));

  return questions;
}
