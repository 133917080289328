import React from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { readFromFirestore } from "../../api/read/readFromFirestore";
import PresentationViewer from "../../components/PresentationViewer";

function Index() {
  const { id } = useParams();
  const { data, isLoading } = useQuery(["presentations", id], () =>
    readFromFirestore("presentations", id)
  );

  console.log(isLoading ? "Loading..." : data);
  if (data) {
    return (
      <div
        style={{
          minHeight: "100vh",
          width: "100vw",
          backgroundColor: "#FFF5E5",
        }}
      >
        <PresentationViewer id={id} type="edit" data={data} />
      </div>
    );
  } else return <></>;
}

export default Index;
