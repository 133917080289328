import { useAuthUser } from "@react-query-firebase/auth";
import { auth } from "../../firebase";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { createLiveRoom } from "../../api/write/createLiveRoom";

function Topbar({ allowEdit, handleAddQuestion, presentationId }) {
  const user = useAuthUser(["user"], auth);
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/presentations");
  };
  if (user.data)
    return (
      <div
        style={{
          width: "100%",
          paddingTop: 16,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        <div
          onClick={() => {
            handleHomeClick();
          }}
          className="shadow"
          style={{
            backgroundColor: "white",
            padding: 12,
            borderRadius: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            position: "relative", // add position relative to the parent div
            marginLeft: 16,
          }}
        >
          <span
            style={{ marginRight: 4, userSelect: "none" }} // add cursor pointer to show it's clickable
          >
            Back to Home
          </span>
        </div>
        <span style={{ fontSize: 32 }}>New Presentation</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            position: "relative", // add position relative to the parent div
            marginRight: 16,
          }}
        >
          {allowEdit ? (
            <>
              <div
                onClick={handleAddQuestion}
                style={{
                  height: 48,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderColor: "#16A085",
                  color: "#16A085",
                  borderWidth: 4,
                  borderRadius: 16,
                  paddingRight: 16,
                  paddingLeft: 16,
                  borderStyle: "solid",
                  cursor: "pointer",
                }}
              >
                Add Question
              </div>
              <div
                onClick={() => {
                  createLiveRoom(presentationId, navigate);
                }}
                style={{
                  height: 56,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 16,
                  paddingRight: 16,
                  paddingLeft: 16,
                  cursor: "pointer",
                  backgroundColor: "white",
                  marginLeft: 16,
                }}
                className="shadow"
              >
                Create Live Room
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
}

export default Topbar;
