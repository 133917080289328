import { writeToFirestore } from "./writeToFirestore";

export async function createLiveRoom(id, navigate) {
  const randomNumber = Math.floor(Math.random() * 900000) + 100000;
  await writeToFirestore("rooms", randomNumber.toString(), {
    presentationId: id,
    currentSlide: 0,
    created: new Date(),
  });
  navigate("/play/" + randomNumber.toString() + "/host");
}
