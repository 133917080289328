import React from "react";

function Sidebar({ data, currentSlide, setCurrentSlide }) {
  return (
    <div
      style={{
        marginLeft: 8,
        width: 260,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {data.map((slide, index) => {
        return (
          <img
            onClick={() => {
              setCurrentSlide(index);
            }}
            alt={`Slide ${index}`}
            src={slide.img}
            style={{ marginBottom: 8, borderRadius: 8 }}
            className={index === currentSlide ? "shadow tweak" : ""}
          />
        );
      })}
    </div>
  );
}

export default Sidebar;
