import React from "react";

function Processing({}) {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="drop_area">
        <img
          style={{ height: 100, width: 100, marginBottom: 16 }}
          src={require("../../assets/loading.gif")}
        />
        <div style={{ fontWeight: "bold", fontSize: 45, textAlign: "center" }}>
          We are processing your PDF!
        </div>
        <span
          style={{
            fontWeight: "bold",
            fontSize: 20,
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          <br />
          Doing some AI magic 🪄
          <br />
          <br />
          It's normal for this to take 1-2 minutes depending on the size of your
          presentation.
          <br />
          <br />
          Please keep this tab open :)
        </span>
      </div>
    </div>
  );
}

export default Processing;
