import React, { useEffect, useState } from "react";
import { useTimer } from "./useTimer";
import HostQuestionResults from "./HostQuestionResults";

function HostQuestion({ content, roomId }) {
  const [remaining, setRemaining] = useState(15);
  const [showResults, setShowResults] = useState(false);
  useTimer(
    15,
    () => {
      setShowResults(true);
    },
    setRemaining
  );

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="shadow"
          style={{
            backgroundColor: "white",
            borderRadius: 8,
            paddingLeft: 18,
            paddingRight: 18,
            paddingTop: 64,
            paddingBottom: 64,
            width: "100%",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {showResults ? (
            <HostQuestionResults roomId={roomId} />
          ) : (
            <>
              <div
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 100,
                  borderStyle: "solid",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: 3,
                  borderColor: "navy",
                  marginBottom: 64,
                }}
              >
                <h1 style={{ color: "navy" }}>{remaining}</h1>
              </div>
              <div style={{ fontSize: 75 }}>{content.question}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default HostQuestion;
