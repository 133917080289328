import React from "react";
import { AiOutlineUser } from "react-icons/ai";

function TopBar({ title }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 16,
      }}
    >
      <span style={{ fontWeight: "bold", fontSize: 30 }}>{title}</span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 8,
        }}
      >
        <div style={{ marginRight: 8 }}>Chase Robbins</div>
        <AiOutlineUser size={28} />
      </div>
    </div>
  );
}

export default TopBar;
