import { useAuthSignOut, useAuthUser } from "@react-query-firebase/auth";
import React, { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router";
import { login } from "../../api/write/login";
import { auth } from "../../firebase";

function TopBar({ user }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const signOutMutation = useAuthSignOut(auth);
  const navigate = useNavigate();

  const handleSignOutClick = () => {
    signOutMutation.mutate();
  };

  const handleSignInClick = () => {
    login();
  };

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handlePresentationsClick = () => {
    navigate("/presentations");
  };

  if (user) {
    return (
      <div
        style={{
          width: "100%",
          paddingTop: 16,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        <span
          style={{
            marginRight: 16,
            color: "white",
            textDecoration: "underline",
            textUnderlineOffset: 4,
            cursor: "pointer",
          }}
          onClick={handlePresentationsClick}
        >
          My Presentations
        </span>
        <div
          className="shadow"
          onClick={handleDropdownClick} // add click handler to show/hide the dropdown
          style={{
            backgroundColor: "white",
            padding: 12,
            borderRadius: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            position: "relative", // add position relative to the parent div
            marginRight: 16,
          }}
        >
          <span
            style={{ marginRight: 4, userSelect: "none" }} // add cursor pointer to show it's clickable
          >
            {user.displayName}
          </span>
          <AiOutlineUser size={28} />
          {showDropdown && ( // render the dropdown if showDropdown is true
            <div
              style={{
                position: "absolute",
                top: "110%",
                right: 0,
                width: "100%",
                backgroundColor: "white",
                padding: 8,
                borderRadius: 8,
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div onClick={handleSignOutClick} style={{ cursor: "pointer" }}>
                Sign Out
              </div>
              {/* <div style={{ cursor: "pointer" }}>Option 2</div> */}
            </div>
          )}
        </div>
      </div>
    );
  } else
    return (
      <div
        style={{
          width: "100%",
          paddingTop: 16,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        <div
          className="shadow"
          style={{
            backgroundColor: "white",
            padding: 12,
            borderRadius: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 16,
            cursor: "pointer",
          }}
          onClick={handleSignInClick}
        >
          <span style={{ marginRight: 4 }}>Sign In</span>
          <AiOutlineUser size={28} />
        </div>
      </div>
    );
}

export default TopBar;
