import React, { useEffect, useState } from "react";
import background from "../../assets/landing_bg.png";
import CodeEntry from "./CodeEntry";
import TeacherLogin from "./TeacherLogin";
import TopBar from "./TopBar";
import { auth } from "../../firebase";
import { useAuthSignOut, useAuthUser } from "@react-query-firebase/auth";
import { getAuth } from "firebase/auth";

function Index() {
  const auth = getAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      setUser(auth.currentUser);
    } else {
      setUser(null);
    }
  }, [auth.currentUser]);

  return (
    <div
      style={{
        backgroundColor: "#2980B9",
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopBar user={user} />
      <CodeEntry user={user} />
    </div>
  );
}

export default Index;
