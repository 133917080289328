import React, { useState } from "react";
import CurrentSlide from "./CurrentSlide";
import QuesitonEditor from "./QuestionEditor/QuesitonEditor";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { writeToFirestore } from "../../api/write/writeToFirestore";
import { useQueryClient } from "react-query";
function Index({ data, type, id }) {
  const [currentIndex, setCurrentIndex] = useState({ type: "slide", index: 0 });
  const allowEdit = type === "edit" ? true : false;
  const queryClient = useQueryClient();

  async function handleAddQuestion() {
    if (currentIndex.type !== "slide") {
      alert(
        "You can't have two consecutive questions. Select the slide you'd like to precede your new question."
      );
      return;
    }
    if (data.questions) {
      data.questions.forEach((question) => {
        if (question.after === currentIndex.index) {
          alert(
            "You already have a question for this slide! Select a different slide."
          );
          return;
        }
      });
    }
    let starter = [];
    if (data.questions) {
      starter = [...data.questions];
    }
    await writeToFirestore("presentations", id, {
      questions: [
        ...starter,
        {
          after: currentIndex.index,
          options: [
            { content: "Option A", correct: true },
            { content: "Option B", correct: false },
            { content: "Option C", correct: false },
            { content: "Option D", correct: false },
          ],
          question: "Edit Question Here",
        },
      ],
    });
    queryClient.invalidateQueries(["presentations", id]);
    setCurrentIndex({ type: "question", index: data.questions.length });
  }

  async function handleEditQuestion(questionData) {
    let payload = [...data.questions];
    payload[currentIndex.index] = questionData;
    await writeToFirestore("presentations", id, {
      questions: payload,
    });
    queryClient.invalidateQueries(["presentations", id]);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden" /* hide overflow from other child elements */,
      }}
    >
      <Topbar
        presentationId={id}
        handleAddQuestion={handleAddQuestion}
        allowEdit={allowEdit}
        title={data && data.title ? data.title : "New Presentation"}
      />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {currentIndex.type === "slide" ? (
          <CurrentSlide currentIndex={currentIndex.index} data={data} />
        ) : (
          <div
            style={{
              flex: 1,
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <QuesitonEditor
              handleEditQuestion={handleEditQuestion}
              currentIndex={currentIndex.index}
              data={data}
            />
          </div>
        )}
        {data ? (
          <Sidebar
            allowEdit={allowEdit}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            data={data}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Index;
