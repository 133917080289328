import React, { useEffect, useState } from "react";

function Sidebar({ data, setCurrentIndex, currentIndex, allowEdit }) {
  return (
    <div
      style={{
        width: 250,
        display: "flex",
        flexDirection: "column",
        height: "95vh",
        position: "relative",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: 8,
          overflow: "auto",
          marginTop: 16,
        }}
      >
        {data &&
          data.files &&
          data.files.map((slide, index) => {
            return (
              <>
                <img
                  key={index}
                  onClick={() => {
                    setCurrentIndex({ type: "slide", index });
                  }}
                  alt={`Slide ${index}`}
                  src={data.files[index]}
                  style={{ marginBottom: 8, borderRadius: 8 }}
                  className={
                    index === currentIndex.index &&
                    currentIndex.type === "slide"
                      ? "shadow tweak"
                      : ""
                  }
                />
                <QuestionButton
                  data={data}
                  index={index}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                />
              </>
            );
          })}
      </div>
    </div>
  );
}

function QuestionButton({ index, data, currentIndex, setCurrentIndex }) {
  const [questionData, setQuestionData] = useState(null);
  useEffect(() => {
    console.log(data.questions);
    if (data.questions) {
      data.questions.forEach((question, _index) => {
        if (question.after === index) {
          setQuestionData({ ...question, index: _index });
        }
      });
    }
  }, [data, index]);

  if (questionData) {
    return (
      <div
        onClick={() => {
          setCurrentIndex({ type: "question", index: questionData.index });
        }}
        style={{
          marginBottom: 8,
          borderRadius: 8,
          backgroundColor: "black",
          padding: 8,
          color: "white",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontWeight: "bold",
        }}
        className={
          questionData.index === currentIndex.index &&
          currentIndex.type === "question"
            ? "shadow tweak"
            : ""
        }
      >
        Comprehension Check
      </div>
    );
  }

  return <></>;
}

export default Sidebar;
