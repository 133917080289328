import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";

export async function fetchUsersPresentations(userId) {
  const db = getFirestore();
  const presentationsRef = collection(db, "presentations");

  // Create a query that filters presentations by owner ID and orders them by lastModified
  const presentationsQuery = query(
    presentationsRef,
    where("owner", "==", userId)
  );

  // Execute the query and get the documents
  const querySnapshot = await getDocs(presentationsQuery);

  // Convert the documents to an array of data objects and sort them by lastModified
  const presentations = querySnapshot.docs
    .map((doc) => ({ id: doc.id, ...doc.data() }))
    .sort((a, b) => b.lastModified.seconds - a.lastModified.seconds);

  return presentations;
}
