import { useEffect, useState } from "react";

export function useTimer(duration, callback, setRemainingTime) {
  const [count, setCount] = useState(duration);

  useEffect(() => {
    let intervalId;
    if (count > 0) {
      intervalId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
        setRemainingTime(count - 1);
      }, 1000);
    } else {
      if (callback) {
        callback();
      }
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [count]);
}
