import {
  doc,
  setDoc,
  updateDoc,
  increment,
  getFirestore,
  getDoc,
} from "firebase/firestore";

export async function addPoints(roomId, points, uid, name) {
  const db = getFirestore();
  const roomRef = doc(db, `rooms/${roomId}/participants`, uid);

  try {
    const docSnap = await getDoc(roomRef);
    if (docSnap.exists()) {
      await updateDoc(roomRef, {
        name: name,
        points: increment(points),
      });
      console.log("Document updated successfully!");
    } else {
      await setDoc(roomRef, {
        name: name,
        points: points,
      });
      console.log("Document created successfully!");
    }
  } catch (e) {
    console.error("Error adding points:", e);
  }
}
