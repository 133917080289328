import React from "react";
import { login } from "../../api/write/login";

function TeacherLogin() {
  return (
    <div
      onClick={() => {
        login();
      }}
      style={{
        width: "100%",
        cursor: "pointer",
        textAlign: "center",
        marginRight: 16,
        color: "white",
        textDecoration: "underline",
        textUnderlineOffset: 4,
        fontSize: 18,
        paddingBottom: 16,
        fontWeight: "bold",
      }}
    >
      Teacher Login
    </div>
  );
}

export default TeacherLogin;
