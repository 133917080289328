import { useAuthUser } from "@react-query-firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { processPDF } from "../../api/write/processPDF";
import { auth } from "../../firebase";
import ImportWizard from "./ImportWizard";
import Processing from "./Processing";
import TopBar from "./TopBar";

function Index() {
  const [file, setFile] = useState(null); //base64 uploaded pdf
  const user = useAuthUser(["user"], auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (file) {
      processPDF(file, user.data.uid).then((id) => {
        navigate("/edit/" + id);
      });
    }
  }, [file]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#FFF5E5",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopBar />
      {file ? <Processing /> : <ImportWizard setFile={setFile} />}
    </div>
  );
}

export default Index;
