import { useAuthUser } from "@react-query-firebase/auth";
import { auth } from "../../firebase";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function TopBar() {
  const user = useAuthUser(["user"], auth);
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/presentations");
  };
  if (user.data)
    return (
      <div
        style={{
          width: "100%",
          paddingTop: 16,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        <div
          onClick={() => {
            handleHomeClick();
          }}
          className="shadow"
          style={{
            backgroundColor: "white",
            padding: 12,
            borderRadius: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            position: "relative", // add position relative to the parent div
            marginLeft: 16,
          }}
        >
          <span
            style={{ marginRight: 4, userSelect: "none" }} // add cursor pointer to show it's clickable
          >
            Back to Home
          </span>
        </div>
        <span style={{ fontSize: 40 }}>New Presentation</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            position: "relative", // add position relative to the parent div
            marginRight: 16,
          }}
        >
          <span
            style={{ marginRight: 4, userSelect: "none" }} // add cursor pointer to show it's clickable
          >
            {user.data.displayName}
          </span>
          <AiOutlineUser size={28} />
        </div>
      </div>
    );
}

export default TopBar;
