import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  getFirestore,
} from "firebase/firestore";

export async function getTopParticipants(roomId) {
  const db = getFirestore();
  const participantsRef = collection(db, `rooms/${roomId}/participants`);
  const q = query(participantsRef, orderBy("points", "desc"), limit(10));

  try {
    const querySnapshot = await getDocs(q);
    const participants = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Top 10 participants:", participants);
    return participants;
  } catch (e) {
    console.error("Error fetching top participants:", e);
  }
}
