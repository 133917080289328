import { useAuthUser } from "@react-query-firebase/auth";
import React from "react";
import { useQuery } from "react-query";
import { fetchUsersPresentations } from "../../api/read/fetchUsersPresentations";
import { auth } from "../../firebase";
import PresentationsList from "./PresentationsList";
import TopBar from "./TopBar";

function Index() {
  const user = useAuthUser(["user"], auth);
  const { data, isLoading } = useQuery(["presentations", user.data.uid], () =>
    fetchUsersPresentations(user.data.uid)
  );

  console.log(isLoading ? "loading" : data);
  return (
    <div
      style={{ width: "100vw", minHeight: "100vh", backgroundColor: "#FFF5E5" }}
    >
      <TopBar />
      {!isLoading ? <PresentationsList data={data} /> : <></>}
    </div>
  );
}

export default Index;
