import React, { useEffect, useState } from "react";
import { getTopParticipants } from "../../api/read/getTopParticipants";
import Confetti from "react-confetti";

function End({ roomId }) {
  const [results, setResults] = useState(null);

  useEffect(() => {
    getTopParticipants(roomId).then((res) => {
      setResults(res);
    });
  }, []);

  if (results) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Confetti />
        <h1>Final Leaderboard</h1>
        {results.map((item, index) => (
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
            }}
          >
            <h1>#{index + 1}</h1>
            <h1>{item.name}</h1>
            <h1>{item.points} points</h1>
          </div>
        ))}
      </div>
    );
  }
  return <></>;
}

export default End;
