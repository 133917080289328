import { writeToFirestore } from "./writeToFirestore";
import { uploadRemoteFileToStorage } from "./uploadRemoteFileToStorage";
import { getQuestionsFromText } from "../read/getQuestionsFromText";

export function processPDF(file, user) {
  return new Promise(async (resolve, reject) => {
    const { Files } = await convertPdfToPng(file);
    const files = await uploadFilesToStorage(Files, user);
    const slideText = await convertPdfToTxt(file);
    const premadeQuestions = await getQuestionsFromText(slideText);

    writeToFirestore("presentations", undefined, {
      files,
      title: "",
      owner: user,
      questions: [],
      slideText,
      lastModified: new Date(),
      premadeQuestions,
    }).then((docRef) => {
      console.log("New doc ID:", docRef.id);
      resolve(docRef.id);
    });
  });
}

async function convertPdfToPng(base64FileContent) {
  const url =
    "https://v2.convertapi.com/convert/pdf/to/png?Secret=5qB370IyjyAI4mv8";
  const headers = {
    "Content-Type": "application/json",
  };
  const parameters = [
    {
      Name: "File",
      FileValue: {
        Name: "my_file.pdf",
        Data: base64FileContent,
      },
    },
    {
      Name: "StoreFile",
      Value: true,
    },
  ];
  const body = JSON.stringify({ Parameters: parameters });

  console.log("Sending request to:", url);

  const response = await fetch(url, {
    method: "POST",
    headers,
    body,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const result = await response.json();

  console.log("Response:", result);

  return result;
}

async function convertPdfToTxt(pdfBase64) {
  const url =
    "https://v2.convertapi.com/convert/pdf/to/txt?Secret=5qB370IyjyAI4mv8";
  const headers = {
    "Content-Type": "application/json",
  };
  const parameters = [
    {
      Name: "File",
      FileValue: {
        Name: "my_file.pdf",
        Data: pdfBase64,
      },
    },
    {
      Name: "StoreFile",
      Value: true,
    },
  ];
  const body = JSON.stringify({ Parameters: parameters });

  console.log("Sending request to:", url);

  const response = await fetch(url, {
    method: "POST",
    headers,
    body,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const result = await response.json();

  console.log("Response:", result.Files[0].Url);
  const txtFileUrl = result.Files[0].Url;
  console.log(`Fetching converted text from ${txtFileUrl}...`);

  const txtResponse = await fetch(txtFileUrl);
  const txtData = await txtResponse.text();

  console.log("Conversion complete!");

  return txtData;
}

async function uploadFilesToStorage(Files, user) {
  const files = [];
  for (const file of Files) {
    const url = await uploadRemoteFileToStorage(
      file.Url,
      `slides/${user}_${Math.floor(Math.random() * 1000000)}.png`
    );
    files.push(url);
  }
  return files;
}
