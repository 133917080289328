import React from "react";
import { getQuestionsFromText } from "../../../api/read/getQuestionsFromText";

function GenerationButtons({ premadeQuestions, handleSelectPremadeQuestion }) {
  return (
    <div>
      <h3>Pre-generated Questions</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // alignItems: "center",
          marginTop: 16,
        }}
      >
        {premadeQuestions.map((item, index) => (
          <Button
            content={item.question}
            callback={() => {
              handleSelectPremadeQuestion(item);
            }}
          />
        ))}
      </div>
    </div>
  );
}

function Button({ content, callback }) {
  return (
    <div
      onClick={callback}
      style={{
        padding: 8,
        backgroundColor: "white",
        borderRadius: 8,
        marginRight: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        flex: 1,
      }}
      className="shadow"
    >
      {content}
    </div>
  );
}

export default GenerationButtons;
