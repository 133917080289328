import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { processPDF } from "../../api/write/processPDF";

function ImportWizard({ setFile }) {
  const [dragging, setDragging] = useState(false);

  const handleDrop = (file) => {
    console.log("File uploaded");
    console.log(file);
    // Create a new FileReader object
    var reader = new FileReader();

    // Define the onload event handler
    reader.onload = function () {
      // Once the reader has finished reading the file, convert it to a base64 encoded string
      var base64String = reader.result.split(",")[1];
      // Do something with the base64 string, such as sending it to a server or displaying it on a webpage
      setFile(base64String);
    };

    // Read the file as a data URL (which includes the base64 encoding)
    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FileUploader
        handleChange={handleDrop}
        name="file"
        types={["PDF"]}
        multiple={false}
        onDraggingStateChange={setDragging}
        classes="drop_area"
      >
        <div style={{ fontWeight: "bold", fontSize: 45, textAlign: "center" }}>
          Drag and Drop your PDF here to import!
        </div>
        <span style={{ fontWeight: "bold", fontSize: 20, cursor: "pointer" }}>
          Or <span style={{ textDecoration: "underline" }}>browse</span> from
          your computer
        </span>
      </FileUploader>
    </div>
  );
}

export default ImportWizard;
