import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export async function uploadRemoteFileToStorage(remoteFileUrl, storagePath) {
  // Get a Storage instance
  const storage = getStorage();

  // Reference to the Storage location where you want to upload the file
  const fileRef = ref(storage, storagePath);

  // Fetch the file content from the remote URL
  const response = await fetch(remoteFileUrl);
  const fileContent = await response.arrayBuffer();

  // Upload the file to Storage
  await uploadBytes(fileRef, fileContent);

  // Get the download URL of the uploaded file
  const downloadUrl = await getDownloadURL(fileRef);

  console.log("File uploaded to Firebase Storage:", downloadUrl);

  return downloadUrl;
}
