import React, { useEffect, useState } from "react";
import { useTimer } from "./useTimer";
import { getTopParticipants } from "../../api/read/getTopParticipants";

function HostQuestionResults({ roomId }) {
  const [showResults, setShowResults] = useState(false);
  const [leaderBoard, setLeaderBoard] = useState([]);

  useEffect(() => {
    if (showResults && leaderBoard.length === 0) {
      getTopParticipants(roomId).then((res) => {
        setLeaderBoard(res);
      });
    }
  }, [showResults]);

  useTimer(
    2.5,
    () => {
      setShowResults(true);
    },
    () => {}
  );
  return (
    <>
      {showResults && leaderBoard.length > 0 ? (
        <>
          <h1>Leaderboard 🏆</h1>
          {leaderBoard.map((item, index) => (
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
              }}
            >
              <h1>#{index + 1}</h1>
              <h1>{item.name}</h1>
              <h1>{item.points} points</h1>
            </div>
          ))}
        </>
      ) : (
        <div style={{ fontSize: 75 }}>Nice job! Tallying the points 🧮</div>
      )}
    </>
  );
}

export default HostQuestionResults;
