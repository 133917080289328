import React, { useEffect, useState } from "react";
import GenerationButtons from "./GenerationButtons";
import Option from "./Option";
import Question from "./Question";

function QuesitonEditor({ handleEditQuestion, currentIndex, data }) {
  const [questionData, setQuestionData] = useState(
    data.questions[currentIndex]
  );

  function pushUpdates() {
    handleEditQuestion(questionData); //pushes update to server
  }

  useEffect(() => {
    setQuestionData(data.questions[currentIndex]);
  }, [data]);

  function setQuestion(str) {
    const obj = questionData;
    obj.question = str;
    setQuestionData({ ...obj });
  }

  function toggleCorrect(index) {
    const obj = questionData;
    console.log(obj);
    obj.options[index].correct = !obj.options[index].correct;
    setQuestionData({ ...obj });
    pushUpdates();
  }

  function changeTextHandler(index, text) {
    const obj = questionData;
    console.log(obj);
    obj.options[index].content = text;
    setQuestionData({ ...obj });
  }

  function handleSelectPremadeQuestion(data) {
    handleEditQuestion({ ...data, after: questionData.after });
  }

  if (questionData) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flex: 1,
          marginTop: 16,
          paddingLeft: 16,
        }}
      >
        <Question
          pushUpdates={pushUpdates}
          setQuestion={setQuestion}
          questionData={questionData}
        />
        <GenerationButtons
          handleSelectPremadeQuestion={handleSelectPremadeQuestion}
          premadeQuestions={data.premadeQuestions ? data.premadeQuestions : []}
        />
        <div
          style={{ marginTop: 16, flex: 1, justifyContent: "space-between" }}
        >
          {questionData.options.map((item, index) => {
            return (
              <Option
                pushUpdates={pushUpdates}
                changeTextHandler={changeTextHandler}
                toggleCorrect={() => {
                  toggleCorrect(index);
                }}
                data={item}
                key={index}
                index={index}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return <></>;
}

export default QuesitonEditor;
