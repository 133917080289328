import {
  doc,
  setDoc,
  getFirestore,
  collection,
  addDoc,
} from "firebase/firestore";

export async function writeToFirestore(path, documentId, data) {
  // Get a Firestore instance
  const db = getFirestore();

  if (documentId) {
    // Reference to the collection you want to add a document to
    const collectionRef = doc(db, path, documentId);

    // Add the document to the collection
    const docRef = await setDoc(collectionRef, data, { merge: true });

    console.log("Document set on Firestore");
    return docRef;
  } else {
    const myCollectionRef = collection(db, path);

    // Add the document to the collection
    const docRef = await addDoc(myCollectionRef, data);
    console.log("Document added to Firestore");
    return docRef;
  }
}
