import React, { useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { readFromFirestore } from "../../api/read/readFromFirestore";
import PresentationViewer from "../../components/PresentationViewer";
import { usePlay } from "./usePlay";
import Opener from "./Opener";
import Question from "./Question";
import HostQuestion from "./HostQuestion";
import End from "./End";

function Index({ host }) {
  const { id } = useParams();
  const [content, setContent] = useState(null);
  const [next, back] = usePlay(id, setContent, content);

  console.log(content);

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100vw",
        backgroundColor: "#FFF5E5",
        display: "flex",
        flexDirection: "column",
        paddingTop: 16,
        alignItems: "center",
      }}
    >
      {content ? (
        <div
          style={{
            height: "90vh",
            width: "95vw",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* OPENER */}
          {content.type === "opener" ? <Opener id={id} host={host} /> : <></>}

          {/* QUESTION (NOT HOST) */}
          {content.type === "question" && !host ? (
            <Question roomId={id} content={content.content} />
          ) : (
            <></>
          )}

          {/* QUESTION (HOST) */}
          {content.type === "question" && host ? (
            <HostQuestion roomId={id} content={content.content} />
          ) : (
            <></>
          )}

          {/* ENDING */}
          {content.type === "end" ? <End roomId={id} /> : <></>}

          {/* SLIDE */}
          {content.type === "slide" ? (
            <img
              alt="Primary Slide"
              src={content.content}
              style={{ borderRadius: 16, maxHeight: "90vh" }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <></>
      )}
      {host ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 16,
            width: "95%",
          }}
        >
          <Button content="Back" callback={back} />
          <div style={{ flex: 1 }} />
          <Button content="Next" callback={next} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function Button({ content, callback }) {
  return (
    <div
      onClick={callback}
      style={{
        marginLeft: 16,
        padding: 16,
        backgroundColor: "white",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      className="shadow"
    >
      {content}
    </div>
  );
}

export default Index;
