import { useAuthUser } from "@react-query-firebase/auth";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { auth } from "../firebase";
import Landing from "../pages/Landing";
import Quiz from "../pages/Quiz";
import Slides from "../pages/Slides";
import CreatePresentation from "../pages/CreatePresentation";
import EditPresentation from "../pages/EditPresentation";
import Presentations from "../pages/Presentations";
import Participate from "../pages/Participate";

function Router() {
  const user = useAuthUser(["user"], auth);
  console.log(user);
  let routes = [
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/*",
      element: <Landing />,
    },
    { path: "/play/:id", element: <Participate /> },
  ];

  const protectedRoutes = [
    { path: "/slides", element: <Slides /> },
    { path: "/quiz", element: <Quiz /> },
    { path: "/new", element: <CreatePresentation /> },
    { path: "/edit/:id", element: <EditPresentation /> },
    { path: "/play/:id/:host", element: <Participate host={true} /> },
    { path: "/presentations", element: <Presentations /> },
  ];

  if (user.data) {
    routes = [...routes, ...protectedRoutes];
  }

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}

export default Router;
