import React, { useState } from "react";
import { useAuthSignOut, useAuthUser } from "@react-query-firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

function CodeEntry({ user }) {
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  function handleClick() {
    if (user && code.length === 6) {
      navigate("play/" + code);
    }
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          width: 430,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontWeight: "black",
            fontSize: 70,
            color: "white",
            marginBottom: 12,
          }}
        >
          Brain Quest
        </span>
        <span
          style={{
            textAlign: "center",
            fontSize: 35,
            color: "white",
            marginBottom: 24,
          }}
        >
          real time slide sharing 🖼️ and AI-generated quizzes 🧠
        </span>

        <div
          className="shadow"
          style={{
            backgroundColor: "white",
            borderRadius: 8,
            padding: 18,
            width: "100%",
          }}
        >
          <div
            style={{
              borderColor: "#C0C0C0",
              borderWidth: 1,
              borderStyle: "solid",
              padding: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
              type="text"
              className="codeEntryBox"
              placeholder="Session Code"
            />
          </div>
          <div
            onClick={handleClick}
            style={{
              marginTop: 8,
              borderRadius: 8,
              padding: 18,
              textAlign: "center",
              backgroundColor: "#3498DB",
              color: "white",
              opacity: code && user ? 1 : 0.75,
              fontSize: 18,
              fontWeight: "bold",
              boxShadow: "0px 5px 0px 0px #2980B9",
            }}
          >
            {user ? "Get Started" : "Please Sign In"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CodeEntry;
// // play/506822/host
