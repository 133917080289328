import React, { useRef } from "react";

function Option({
  data,
  index,
  toggleCorrect,
  changeTextHandler,
  pushUpdates,
}) {
  const ref = useRef();

  function getColor() {
    switch (index) {
      case 0:
        return "#16A085";
      case 1:
        return "#3498DB";
      case 2:
        return "#E98934";
      case 3:
        return "#FB5847";
      default:
        return "white";
    }
  }
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginBottom: 8,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Option {index + 1}</span>
        <div
          onClick={() => {
            toggleCorrect();
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 8,
            backgroundColor: "white",
            borderRadius: 8,
            cursor: "pointer",
          }}
          className="shadow"
        >
          {data.correct ? "Correct ✅" : "Incorrect ❌"}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <textarea
          style={{
            flex: 1,
            backgroundColor: getColor(),
            borderRadius: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            fontSize: 36,
            marginBottom: 16,
            resize: "none",
            height: "auto",
            textAlign: "center",
            padding: 32,
            verticalAlign: "middle",
          }}
          ref={ref}
          onBlur={() => {
            pushUpdates();
          }}
          onChange={(e) => {
            changeTextHandler(index, e.target.value);
          }}
          value={data.content}
          className="shadow"
        />
      </div>
    </div>
  );
}

export default Option;
