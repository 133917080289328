import React, { useEffect, useState } from "react";
import { useTimer } from "./useTimer";
import { addPoints } from "../../api/write/addPoints";
import ReactConfetti from "react-confetti";
import { getAuth } from "firebase/auth";

function Question({ content, roomId }) {
  const [selected, setSelected] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [remainingTime, setRemainingTime] = useState(15);
  const [pointsAdded, setPointsAdded] = useState(null);

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (selected !== null) {
      if (content.options[selected].correct) {
        setPointsAdded(40 + 4 * remainingTime);
        addPoints(roomId, 40 + 4 * remainingTime, user.uid, user.displayName);
      } else {
        setPointsAdded(0);
      }
    }
  }, [selected, content]);

  useTimer(
    15,
    () => {
      setShowResults(true);
    },
    setRemainingTime
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 100,
            height: 100,
            borderRadius: 100,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderWidth: 3,
            borderColor: "navy",
          }}
        >
          <h1 style={{ color: "navy" }}>{remainingTime}</h1>
        </div>
        <h1 style={{ fontSize: 60 }}>{content.question}</h1>
        {pointsAdded !== null && remainingTime === 0 ? (
          <h2>
            {pointsAdded !== 0 ? <ReactConfetti /> : <></>}➕{pointsAdded}{" "}
            points{pointsAdded !== 0 ? "! Nice work!" : ""}
          </h2>
        ) : (
          <></>
        )}
      </div>
      <ButtonRow>
        <Button
          callback={() => {
            setSelected(0);
          }}
          color="#1CAF92"
          option={content.options[0].content}
          showResults={showResults}
          allowClick={selected === null && !showResults}
          selected={selected === 0}
          correct={content.options[0].correct}
        />
        <div style={{ width: 32 }} />
        <Button
          callback={() => {
            setSelected(1);
          }}
          showResults={showResults}
          color="#3CA5EC"
          option={content.options[1].content}
          allowClick={selected === null && !showResults}
          selected={selected === 1}
          correct={content.options[1].correct}
        />
      </ButtonRow>
      <div style={{ height: 16 }} />
      <ButtonRow>
        <Button
          showResults={showResults}
          callback={() => {
            setSelected(2);
          }}
          color="#E98934"
          option={content.options[2].content}
          allowClick={selected === null && !showResults}
          selected={selected === 2}
          correct={content.options[2].correct}
        />
        <div style={{ width: 32 }} />
        <Button
          callback={() => {
            setSelected(3);
          }}
          showResults={showResults}
          color="#E96557"
          option={content.options[3].content}
          allowClick={selected === null && !showResults}
          selected={selected === 3}
          correct={content.options[3].correct}
        />
      </ButtonRow>
    </div>
  );
}

function ButtonRow({ children }) {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        height: 10,
      }}
    >
      {children}
    </div>
  );
}

function Button({
  color,
  option,
  callback,
  selected,
  allowClick,
  showResults,
  correct,
}) {
  return (
    <div
      style={{
        opacity: !allowClick ? (selected ? 1 : 0.5) : 1,
        height: "100%",
        borderRadius: 16,
        width: "100%",
        backgroundColor: color,
        cursor: allowClick ? "pointer" : null,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        color: "white",
        fontSize: 35,
        fontWeight: "bold",
        textAlign: "center",
        position: "relative",
      }}
      onClick={() => {
        if (allowClick) {
          callback();
        }
      }}
    >
      {showResults ? (
        <div style={{ position: "absolute", top: 32, left: 32 }}>
          {correct ? "✅" : "❌"}
        </div>
      ) : (
        <></>
      )}
      {option}
    </div>
  );
}

export default Question;
