import React, { useRef } from "react";

function Question({ questionData, setQuestion, pushUpdates }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ fontWeight: "bold", fontSize: 22, marginBottom: 8 }}>
        Question
      </span>
      <textarea
        style={{
          backgroundColor: "white",
          borderRadius: 16,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          fontSize: 36,
          resize: "none",
          textAlign: "center",
          padding: 32,
        }}
        onBlur={() => {
          pushUpdates();
        }}
        onChange={(e) => {
          setQuestion(e.target.value);
        }}
        value={questionData.question}
        className="shadow"
      />
    </div>
  );
}

export default Question;
