import React from "react";

function Opener({ host, id }) {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {host ? (
        <div
          className="shadow"
          style={{
            backgroundColor: "white",
            borderRadius: 8,
            padding: 18,
            width: "100%",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: 52 }}>Today's Brain Quest</h1>
          <h2 style={{ textDecorationLine: "underline", fontSize: 52 }}>
            brainquest.io
          </h2>
          <h1 style={{ fontSize: 100, color: "navy" }}>{id}</h1>
        </div>
      ) : (
        <h1>Welcome to Today's Brain Quest. The quest will begin soon!</h1>
      )}
    </div>
  );
}

export default Opener;
