import { getFirestore, doc, getDoc } from "firebase/firestore";

export async function readFromFirestore(path, documentId) {
  // Get a Firestore instance
  const db = getFirestore();

  // Reference to the document you want to read
  const documentRef = doc(db, path, documentId);

  // Get the document from Firestore
  const docSnap = await getDoc(documentRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    console.log("Document read from Firestore", documentId);
    return data;
  } else {
    console.log("No such document exists in Firestore");
    return null;
  }
}
