import React from "react";

function CurrentSlide({ data, currentIndex }) {
  return (
    <div style={{ flex: 1, padding: 16 }}>
      <img
        alt="Primary Slide"
        src={data.files[currentIndex]}
        style={{ borderRadius: 16, width: "100%" }}
      />
      {/* <div
        style={{
          backgroundColor: "white",
          padding: 8,
          borderRadius: 16,
          marginTop: 16,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <textarea placeholder="Notes" className="notesBox" />
      </div> */}
    </div>
  );
}

export default CurrentSlide;
