import React, { useState } from "react";
import PrimarySlide from "./PrimarySlide";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";

const SAMPLE_DATA = {
  title: "Support + Feed Lecture 2",
  slides: [
    { img: "https://i.ibb.co/yy0Kz1G/IF-Support-Feed-Campaign.png", notes: "" },
    {
      img: "https://i.ibb.co/bPGdFzy/IF-Support-Feed-Campaign-1.png",
      notes: "",
    },
    {
      img: "https://i.ibb.co/Ms0FYcs/IF-Support-Feed-Campaign-2.png",
      notes: "",
    },
    {
      img: "https://i.ibb.co/THgY8mq/IF-Support-Feed-Campaign-3.png",
      notes: "",
    },
  ],
};

function Index() {
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#FFF5E5",
        padding: 16,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopBar title={SAMPLE_DATA.title} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <PrimarySlide data={SAMPLE_DATA.slides[currentSlide]} />
        <Sidebar
          data={SAMPLE_DATA.slides}
          setCurrentSlide={setCurrentSlide}
          currentSlide={currentSlide}
        />
      </div>
    </div>
  );
}

export default Index;
