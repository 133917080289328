import React from "react";

function PrimarySlide({ data }) {
  console.log(data);
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <img alt="Primary Slide" src={data.img} style={{ borderRadius: 16 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 8,
          borderRadius: 16,
          marginTop: 16,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <textarea placeholder="Notes" className="notesBox" />
      </div>
    </div>
  );
}

export default PrimarySlide;
