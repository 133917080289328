import React from "react";
import { useNavigate } from "react-router";
import { formatDate } from "../../utils/formatDate";
import { AiOutlinePlus } from "react-icons/ai";

function PresentationsList({ data }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: 16,
      }}
    >
      {data.map((item, index) => {
        return <Listing key={index} item={item} />;
      })}
      <div
        onClick={() => {
          navigate("/new");
        }}
        style={{
          width: 300,
          minHeight: 150,
          borderColor: "#16A085",
          borderWidth: 8,
          borderStyle: "solid",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderRadius: 16,
          cursor: "pointer",
        }}
      >
        <AiOutlinePlus size={72} color="#16A085" />
      </div>
    </div>
  );
}

function Listing({ item }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate("/edit/" + item.id);
      }}
      className="shadow"
      style={{
        width: 300,
        marginRight: 16,
        backgroundColor: "white",
        borderRadius: 16,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        marginBottom: 16,
      }}
    >
      <img src={item.files[0]} style={{ flex: 1, borderRadius: 16 }} />
      <span style={{ fontSize: 26, fontWeight: "bold", marginTop: 8 }}>
        {item.title ? item.title : "Untitled Presentation"}
      </span>
      <span style={{ fontSize: 14 }}>
        Last Modified: {formatDate(item.lastModified.seconds)}
      </span>
    </div>
  );
}
export default PresentationsList;
