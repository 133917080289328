// Import the functions you need from the SDKs you need
import { getFirestore } from "@firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmSn1nO7Xac0WT9MeqUjFu_VMGjafECzU",
  authDomain: "kickball-85155.firebaseapp.com",
  projectId: "kickball-85155",
  storageBucket: "kickball-85155.appspot.com",
  messagingSenderId: "302628271235",
  appId: "1:302628271235:web:bc3f2caf444f01d89697b6",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
